import axios from "axios";
import { useQuery } from "react-query";

import { NavigationItem } from "../model";
import { sleep } from "../helpers";

export const useNavigation = () => {
  const { status, data, error, isFetching } = useQuery(
    "model/navigation",
    async () => {
      await sleep(1000);
      const { data } = await axios.get<NavigationItem[]>("/api/model/navigation");
      return data;
    },
    {
      staleTime: Infinity,
    }
  );

  return { status, data, error, isFetching };
};
