import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./store";
import { useNavigation } from "./services/navigation";
import { Loading } from "./components/Loading";
import { Layout } from "./components/Layout";
import { Login } from "./components/Login";

const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

const LoggedIn = () => {
  const { isFetching } = useNavigation();
  return isFetching ? (
    <Loading />
  ) : (
    <Router>
      <Layout />
    </Router>
  );
};

const LoggedOut = () => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  </Router>
);

export const App = () => <Provider store={store}>{isLoggedIn ? <LoggedIn /> : <LoggedOut />}</Provider>;
