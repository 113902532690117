import React from "react";
import { Button } from "antd";

export const Login = () => {
  const handleClick = () => {
    localStorage.setItem("isLoggedIn", "true");
    window.location.href = "/";
  };

  return (
    <div style={{ textAlign: "center", padding: 100 }}>
      <Button type="primary" onClick={handleClick}>
        Login
      </Button>
    </div>
  );
};
