import React from "react";

interface Props {
  name: string;
}

export const Home: React.FC<Props> = ({ name }) => (
  <div>
    <h1>Home!</h1>
  </div>
);
