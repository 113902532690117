import React from "react";
import { Switch, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { Tabs } from "antd";

import { NavigationItem } from "../../model";
import { ResourceView } from "../ResourceView";
import { ThirdLevelLayout } from "./ThirdLevelLayout";

const { TabPane } = Tabs;

interface Props {
  item: NavigationItem;
}

export const SecondLevelLayout: React.FC<Props> = ({ item }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const pathnameTokens = pathname.split("/").filter((p) => p.length > 0);
  const parentPath = `/${pathnameTokens[0]}`;
  const childPath = `/${pathnameTokens[1]}`;

  const handleTabChange = (activeKey: string) => {
    history.push(`${parentPath}${activeKey}`);
  };

  return (
    <Switch>
      {item.children && <Redirect exact from={parentPath} to={`${parentPath}${item.children[0].path}`} />}
      {item.children?.map((child) => (
        <Route key={child.path} path={`${parentPath}${child.path}`}>
          <Tabs defaultActiveKey={childPath} onChange={handleTabChange} animated={false}>
            {item.children?.map((child) => (
              <TabPane key={child.path} tab={child.title}>
                <ResourceView name={child.resourceName || "placeholder"} />
              </TabPane>
            ))}
          </Tabs>
          {item.children && <ThirdLevelLayout item={child} />}
        </Route>
      ))}
    </Switch>
  );
};
