import React, { useState } from "react";
import { Switch, Route, Redirect, Link, useLocation } from "react-router-dom";
import { Layout as AntdLayout, Row, Col, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined, DashboardOutlined } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { useNavigation } from "../../services/navigation";
import { Home } from "../Home";
import { ResourceView } from "../ResourceView";
import { SecondLevelLayout } from "./SecondLevelLayout";
import styles from "./index.module.scss";

const { Header, Sider, Content } = AntdLayout;

export const Layout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { data } = useNavigation();
  const location = useLocation();

  const items = data?.filter((item) => item.icon) || [];

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    window.location.href = "/";
  };

  return (
    <AntdLayout className={styles.container}>
      <Sider trigger={null} collapsed={collapsed} collapsible>
        <Link to="/">
          <div className={styles.logo} />
        </Link>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[`/${location.pathname.split("/")[1]}`]}>
          <Menu.Item icon={<DashboardOutlined />}>
            <Link to="/home">Home</Link>
          </Menu.Item>
          {items.map((item) => (
            <Menu.Item key={item.path} icon={React.createElement((Icons as any)[item.icon], {})}>
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <AntdLayout>
        <Header className={styles.header} style={{ padding: 0 }}>
          <Row>
            <Col span={12}>
              {collapsed ? (
                <MenuUnfoldOutlined className={styles.icon} onClick={toggle} />
              ) : (
                <MenuFoldOutlined className={styles.icon} onClick={toggle} />
              )}
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <LogoutOutlined className={styles.icon} onClick={logout} />
            </Col>
          </Row>
        </Header>
        <Content className={styles.content}>
          <Switch>
            <Redirect exact from="/" to={items[0].path} />
            <Route exact path="/home" component={Home} />
            {items?.map((item) => (
              <Route key={item.path} path={item.path}>
                {item.children ? (
                  <SecondLevelLayout item={item} />
                ) : (
                  <ResourceView name={item.resourceName || "placeholder"} />
                )}
              </Route>
            ))}
          </Switch>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};
