import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./index.module.scss";

export const Loading = () => (
  <div className={styles.container}>
    <LoadingOutlined className={styles.icon} />
  </div>
);
