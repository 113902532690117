import React from "react";

import { useResource } from "../../services/resource";
import { Placeholder } from "../Placeholder";

interface Props {
  name: string;
}

export const ResourceView: React.FC<Props> = ({ name }) => {
  const { data: resource } = useResource(name);
  return <Placeholder name={resource?.name || ""} />;
};
