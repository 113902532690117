import React from "react";

interface Props {
  name: string;
}

export const Placeholder: React.FC<Props> = ({ name }) => (
  <div>
    <h1>({name})</h1>
  </div>
);
